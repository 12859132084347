<template>
  <div>
    <div :class="containerClass" @click="onWrapperClick">
      <div class="layout-sidebar" @click="onSidebarClick">
        <div class="layout-logo-wrapper">
          <router-link to="/" class="layout-logo-text">
            <span>CONSIGNATÁRIA</span>
          </router-link>
        </div>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>

      <AppTopBar :class="topBarClass" @menu-toggle="onMenuToggle" />
      <div class="layout-main-container">
        <div class="layout-main">
          <router-view />
        </div>
        <AppFooter />
      </div>

      <transition name="layout-mask">
        <div
          v-if="mobileMenuActive"
          class="layout-mask p-component-overlay"></div>
      </transition>
    </div>
  </div>
</template>

<script>
import AppTopbar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppFooter from './AppFooter.vue'

export default {
  components: {
    AppTopBar: AppTopbar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'MENU',
          items: [
            // {
            //   label: 'Dashboard',
            //   icon: 'pi pi-fw pi-home',
            //   to: { name: 'dashboard' },
            // },
            {
              label: 'Servidores',
              icon: 'pi pi-fw pi-id-card',
              to: { name: 'servidores' },
            },
            {
              label: 'Arquivo',
              icon: 'pi pi-fw pi-sitemap',
              items: [
                {
                  label: 'Importar Arquivo',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'importar-arquivo' },
                },
              ],
            },
            // {
            //   label: 'Config. perfil de usuário',
            //   icon: 'pi pi-fw pi-user',
            //   to: { name: 'configuracao-perfil' },
            // },
            {
              label: 'Usuarios',
              icon: 'pi pi-fw pi-users',
              to: { name: 'usuarios' },
              visible: this.$auth.hasRoleConsignatariaAdmin(),
            },
            // {
            //   label: 'Relatórios',
            //   icon: 'pi pi-fw pi-print',
            //   to: { name: 'relatorio' },
            // },
          ],
        },
      ],
    }
  },

  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },

    topBarClass() {
      return [
        {
          'layout-topbar-responsive':
            this.staticMenuInactive && this.layoutMode === 'static',
        },
      ]
    },
  },

  created() {
    this.ativarTimerParaAtualizarToken()
    if (this.tokenExpirado()) {
      this.logout()
    }
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    tempoRestante() {
      return this.$auth.getParsedToken().exp - Date.now() / 1000
    },

    tokenExpirado() {
      return this.tempoRestante() < 0
    },

    logout() {
      clearInterval(this.timer)
      this.$auth.logout()
      window.location.reload()
    },

    ativarTimerParaAtualizarToken() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.tokenExpirado()) {
            this.logout()
          } else if (this.tempoRestante() < 140) {
            this.$auth.refreshToken()
          }
        }, 70000)
      }
    },

    isDesktop() {
      return window.innerWidth >= 992
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },

    onSidebarClick() {
      this.menuClick = true
    },

    onMenuToggle() {
      this.menuClick = true
      if (this.isDesktop()) {
        this.staticMenuInactive = !this.staticMenuInactive
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }
      event.preventDefault()
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
  },
}
</script>
