<template>
  <div class="layout-topbar">
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <Dropdown
      v-if="!loading"
      v-model="store.consignatariaSelecionada"
      class="ml-3 layout-topbar-consignataria-dropdown"
      :options="store.consignatarias"
      optionLabel="razaoSocial"
      placeholder="Selecione a consignatária" />
    <Skeleton v-if="loading" class="ml-2" width="50%" />

    <span class="layout-topbar-user-name"> {{ $auth.user.nomecompleto }} </span>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="toggleMenu">
      <i class="pi pi-user"></i>
      <span>Usuário</span>
    </button>
    <Menu ref="profilemenu" :model="items" :popup="true" />
  </div>
</template>

<script>
import { watch } from 'vue-demi'
import ConsignatariaService from '@/service/ConsignatariaService'
import { consignatariaStore } from '@/stores/consignataria'

export default {
  setup() {
    const store = consignatariaStore()
    return { store }
  },

  data() {
    return {
      loading: false,
      items: [
        {
          label: this.$auth.user.nomecompleto,
          disabled: true,
          class: 'menu-item-responsive',
        },
        {
          separator: true,
          class: 'menu-item-responsive',
        },
        {
          label: 'Sair',
          icon: 'pi pi-sign-out',
          command: () => {
            this.$router.push('/logout')
          },
        },
      ],
    }
  },

  created() {
    this.loading = true
    this.service = new ConsignatariaService(this.$http)
    this.service
      .getConsignatariaLogada()
      .then((res) => {
        console.log('RES', res)
        this.loading = false
        this.store.$reset()
        this.store.consignatarias = res
        if (!this.store.consignatariaSelecionada) {
          if (res.length === 1) {
            this.store.consignatariaSelecionada = res[0]
            localStorage.setItem(
              'consignatariaSelecionada',
              JSON.stringify(res[0]),
            )
          }
        }

        if (localStorage.getItem('consignatariaSelecionada')) {
          this.store.consignatariaSelecionada = JSON.parse(
            localStorage.getItem('consignatariaSelecionada'),
          )
        } else {
          if (
            this.$route.name !== 'servidores' &&
            this.$route.name !== 'usuarios'
          ) {
            this.$router.replace({ name: 'servidores' })
          }
        }

        watch(
          () => this.store.consignatariaSelecionada,
          (state) => {
            const saved = localStorage.getItem('consignatariaSelecionada')
            if (saved !== JSON.stringify(state)) {
              if (
                this.$route.name !== 'servidores' &&
                this.$route.name !== 'usuarios'
              ) {
                this.$router.replace({ name: 'servidores' })
              }
            }

            localStorage.setItem(
              'consignatariaSelecionada',
              JSON.stringify(state),
            )
          },
          { deep: true },
        )
      })
      .catch((err) => {
        this.loading = false
        const mensagem = err.response
          ? err.response.data.message
          : 'Erro de conexão com a aplicação.'
        this.$toast.add({
          severity: 'error',
          summary: mensagem,
          life: 10000,
        })
        this.$router.push('/logout')
      })
  },

  methods: {
    toggleMenu(event) {
      this.$refs.profilemenu.toggle(event)
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event)
    },
  },
}
</script>
